import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { FC, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Container } from '../style';
import { AppLogo } from './common/appLogo';


const FooterWrapper = styled.footer`
  background: ${(props) => props.theme.colors.primary};
  text-align: center;
  color: #ffffffcc;
  padding: 55px 0;
  font-size: 12px;
  line-height: 1.7;
  font-weight: 500;
  @media (max-width: 568px) {
    font-size: 8px;
    font-weight: 500;
    padding: 21px 0 12px;
  }
  .app-logo {
    width: 178px;
    height: 79px;
    filter: brightness(11);
    @media (max-width: 568px) {
      width: 79px;
      height: 35px;
    }
  }
  span,
  h4,
  a {
    opacity: 0.8;
  }
`;
const FooterGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 568px) {
    gap: 3px;
  }
`;
const FooterColumn = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  &:last-child {
    text-align: right;
  }
  @media (max-width: 568px) {
    gap: 3px;
  }
  .app-logo {
    margin-left: -10px;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  h5 {
    color: #fff;
    margin-bottom: 5px;
    font-family: Poppins;
    line-height: 1;
    @media (max-width: 568px) {
      font-size: 8px;
      font-family: Jura;
      font-weight: 700;
    }
  }
  h4 {
    color: #fff;
    margin-bottom: 0px;
    font-family: Poppins;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const FooterBottom = styled.div`
  text-align: right;
  line-height: 2.5;
  margin-top: 40px;
  @media (max-width: 991px) {
    margin-top: 8px;
  }
  @media (max-width: 568px) {
    font-size: 6px;
    line-height: 2;
  }
  a {
    color: inherit;
    text-decoration-line: underline;
  }
`;

const FooterVisibleMobileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  h4 {
    color: #fff;
    margin-bottom: 10px;
    font-family: Jura;
    opacity: 0.8;
    line-height: 1.2;
    text-align: right;
    :last-child {
      margin-bottom: 0;
    }
    @media (max-width: 568px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
`;

export const Footer: FC = () => {
  const [matches, setMatches] = useState(
    // window.matchMedia("(min-width: 992px)").matches
    false,
  );

  useEffect(() => {
    window
      .matchMedia('(min-width: 992px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <FooterWrapper>
      <Container>
        {!matches && (
          <FooterVisibleMobileRow>
            <AppLogo />
            <section>
              <h4>plugmein@fulltothebrim.com</h4>
              <h4>+44 (0)20 7097 5911</h4>
            </section>
          </FooterVisibleMobileRow>
        )}
        <FooterGrid>
          <FooterColumn className="text-left">
            {matches && <AppLogo />}
            <span>
              Brim Chargers Ltd
              <br />
              Motley House
              <br />
              Tunbridge Wells
              <br />
              TN12 7LX
            </span>
            {/* <a href="mailto:plugmein@fulltothebrim.com">
              plugmein@fulltothebrim.com
            </a>
            <a href="phone:+44 (0)20 7097 5911">+44 (0)20 7097 5911</a> */}
          </FooterColumn>

          <FooterColumn>
            <h5>Navigation</h5>
            <AnchorLink to="/#section-5">Our Trusted Clients</AnchorLink>
            <AnchorLink to="/#section-1">Features</AnchorLink>
            <AnchorLink to="/#section-3">
              How to connect Remote Spaces?
            </AnchorLink>
            <AnchorLink to="/#section-4">What makes Brim Different?</AnchorLink>
            <AnchorLink to="/#section-6">Contact Form</AnchorLink>
          </FooterColumn>
          <FooterColumn>
            <h5>Downloads</h5>
            <span>Installation Guide</span>
            <span>App User Manual</span>
          </FooterColumn>
          {matches && (
            <FooterColumn>
              <h5>Subscribe</h5>
              <span>Newsletter</span>
              <span>Mailing List</span>
            </FooterColumn>
          )}
          {matches && (
            <FooterColumn className="text-right">
              <h4>plugmein@fulltothebrim.com</h4>
              <h4>+44 (0)20 7097 5911</h4>

              <FooterBottom>
                <Link to="/terms_and_conditions">Terms & Conditions</Link> |{' '}
                <Link to="/privacy_and_terms">Privacy & Terms</Link> |{' '}
                <a download target='_blank' href="/Brim-Chargers-Sustainability-Policy.pdf">Sustainability</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Climate-Change.pdf">Climate Change</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Waste-Management-Policy.pdf">Waste Management</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Whistleblowing-Policy.pdf">Whistleblowing</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Grievance-Policy.pdf">Grievance</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Anti-Bribery-Policy.pdf">Anti-bribery & Corruption</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Environmental-Policy.pdf">
              Environmental
            </a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Modern-Slavery-Anti-Slavery-Policy.pdf">Modern Slavery</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Human-Rights-Statement.pdf">Human Rights</a>
                <br />© Copyright Brim Chargers Ltd
              </FooterBottom>
            </FooterColumn>
          )}
        </FooterGrid>
        {!matches && (
          <FooterBottom>
            <Link to="/terms_and_conditions">Terms & Conditions</Link> |{' '}
            <Link to="/privacy_and_terms">Privacy & Terms</Link> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Sustainability-Policy.pdf">Sustainability</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Climate-Change.pdf">Climate Change</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Waste-Management-Policy.pdf">Waste Management</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Whistleblowing-Policy.pdf">Whistleblowing</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Grievance-Policy.pdf">Grievance</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Anti-Bribery-Policy.pdf">Anti-bribery & Corruption</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Environmental-Policy.pdf">
              Environmental
            </a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Modern-Slavery-Anti-Slavery-Policy.pdf">Modern Slavery</a> |{' '}
            <a download target='_blank' href="/Brim-Chargers-Human-Rights-Statement.pdf">Human Rights</a>

            <br />© Copyright Brim Chargers Ltd
          </FooterBottom>
        )}
      </Container>
    </FooterWrapper>
  );
};
